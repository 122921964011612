<template>
  <div>
    <div class="mb-4">
      <v-card class="pa-6 mx-0">
        <v-row class="justify-space-between align-center">
          <v-col
            md="4"
            xl="4"
            cols="12"
            sm="12"
          >
            <h2>Snap Book</h2>
            <small style="white-space: normal;">Halaman untuk menambahkan snap book</small>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div>
      <v-row
        v-if="snapBooks.length || !isLoadingData"
        class="align-center"
      >
        <v-col

          md="4"
          cols="12"
        >
          <a
            class="text-decoration-none"
            @click.prevent="showFormAdd()"
          >
            <v-card
              height="290"
              color="#F5F5F5"
              style="border: 1px dashed #c4c4c4;"
              class="d-flex align-center justify-center"
            >
              <div class="text-center">
                <v-icon size="40">
                  {{ icons.mdiPlus }}
                </v-icon>
                <p>Tambah Snap Book</p>
              </div>
            </v-card>
          </a>
        </v-col>
        <v-col
          v-for="(dailySnapBook, index) in snapBooks"
          :key="index"
          md="4"
          cols="12"
        >
          <v-card
            height="290"
          >
            <v-card
              class="overflow-hidden"
              style="z-index: 0"
              color="#AC268F"
            >
              <v-card-title
                class="pa-2 position-absolute"
                style="right: 0; z-index: 1;"
              >
                <v-spacer></v-spacer>
                <v-menu
                  offset-y
                  nudge-bottom="5"
                  nudge-left="60"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#b6b6b6"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <a @click.prevent="showFormEdit(dailySnapBook.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/edit.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2">Edit</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                    <a @click.prevent="confirmDestroy(dailySnapBook.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex btn-delete">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/trash-red.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2 text-delete">Hapus</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
              </v-card-title>
              <div
                style="z-index: 0;"
              >
                <v-img
                  :src="dailySnapBook.thumbnail"
                  height="150"
                ></v-img>
              </div>
            </v-card>
            <v-card-text class="py-4">
              <div class="mb-4">
                <h2 class="font-weight-bold text-truncate mb-2">
                  {{ dailySnapBook.library.name }}
                </h2>
                <p class="font-weight-light text-truncate mb-2">
                  {{ dailySnapBook.content }}
                </p>
              </div>

              <div class="d-flex">
                <div class="me-2 mb-2">
                  <v-img
                    src="@/assets/icons/calendar.svg"
                    sizes="20"
                  ></v-img>
                </div>
                <span class="font-weight-light">{{ dailySnapBook.start_date }} - {{ dailySnapBook.end_date }} </span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          v-for="i in 3"
          :key="i"
          md="4"
        >
          <v-skeleton-loader
            type="card"
            :loading="true"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Snap Book': 'Edit Snap Book'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <FileInput
            v-model="initialImage"
            label="Gambar"
            outlined
            dense
            :loading-image="loadingImage"
            :disabled="isLoadingData"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          ></FileInput>
        </div>
        <!-- <div v-show="showCropper">
          <v-row>
            <v-col cols="12">
              <section class="cropper-area mb-6">
                <div
                  v-if="initialImage === null"
                  class="cursor-pointer text-decoration-none"
                >
                  <v-card
                    height="350"
                    color="#efefef"
                    outlined
                    class="d-flex align-center justify-center rounded-lg"
                  >
                    <div class="text-center">
                      <v-img
                        src="@/assets/icons/black/gallery-add.svg"
                        width="70"
                        class="mx-auto"
                      ></v-img>
                      <h3 clas="ma-0 text--success">
                        Tambah Slider
                      </h3>
                    </div>
                  </v-card>
                </div>
                <div
                  v-else
                  class="d-flex justify-start flex-column"
                >
                  <div>
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="2.91/1"
                      :src="imgSrc"
                      preview=".preview"
                      :auto-crop-area="0.9"
                      :min-container-width="250"
                      :min-container-height="180"
                      drag-mode="move"
                      :view-mode="0"
                      :movable="true"
                      :crop-box-resizable="true"
                    />
                  </div>
                  <div class="actions text-center py-2">
                    <v-btn
                      color="primary"
                      outlined
                      small
                      class="mr-3 btn-reset"
                      @click="reset"
                    >
                      <v-icon left>
                        {{ icons.mdiRefresh }}
                      </v-icon>
                      Reset
                    </v-btn>
                    <v-btn
                      color="primary"
                      small
                      class="btn-crop"
                      @click="cropImage"
                    >
                      <v-icon left>
                        {{ icons.mdiCrop }}
                      </v-icon>
                      Crop
                    </v-btn>
                  </div>
                </div>
              </section>
            </v-col>
            <v-col
              v-show="showPreview == true"
              cols="12"
              class="pt-0"
            >
              <section class="preview-area mb-6">
                <p class="mt-0 mb-8">
                  Preview
                </p>
                <div class="cropped-image">
                  <img
                    v-if="cropImg"
                    :src="cropImg"
                    alt="Cropped Image"
                    class="rounded-lg"
                  />
                  <div
                    v-else
                    class="crop-placeholder rounded-lg"
                  />
                </div>
              </section>
            </v-col>
          </v-row>
        </div> -->
        <div>
          <v-autocomplete
            v-model="snapBook.library.uuid"
            label="Nama Buku"
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :items="libraries"
            :loading="isLoadingLib"
            :disabled="isLoadingLib"
            :search-input.sync="searchBook"
            hide-no-data
            no-filter
          ></v-autocomplete>
        </div>
        <div>
          <v-menu
            v-model="start_date"
            :close-on-content-click="true"
            nudge-right="0"
            nudge-top="20"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="snapBook.start_date"
                label="Waktu Mulai"
                readonly
                outlined
                dense
                v-bind="attrs"
                :loading="isLoadingData"
                :disabled="isLoadingData"
                v-on="on"
                @click="autofillStartDate"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="snapBook.start_date"
              :first-day-of-week="0"
              locale="id-id"
              :min="new Date().toISOString().substr(0, 10)"
              color="primary"
            ></v-date-picker>
          </v-menu>
        </div>
        <div>
          <v-menu
            v-model="end_date"
            :close-on-content-click="true"
            nudge-right="0"
            nudge-top="20"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="snapBook.end_date"
                label="Waktu Selesai"
                readonly
                outlined
                dense
                v-bind="attrs"
                :loading="isLoadingData"
                :disabled="isDisabled"
                v-on="on"
                @click="autofillEndDate(snapBook.start_date)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="snapBook.end_date"
              :first-day-of-week="0"
              locale="id-id"
              :min="minEndDate"
              color="primary"
            ></v-date-picker>
          </v-menu>
        </div>
        <div>
          <v-textarea
            v-model="snapBook.content"
            label="Kutipan Buku"
            outlined
            dense
          ></v-textarea>
        </div>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
      persistent
      :width="600"
      :is-loading-button="isLoadingButton"
      header="Hapus Snap Book"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCrop, mdiDotsVertical, mdiImageMultipleOutline, mdiPlus, mdiRefresh } from '@mdi/js'
import 'cropperjs/dist/cropper.css'
import ConfirmDialog from '../components/ConfirmDialog.vue'
import FileInput from '../components/FileInput.vue'
import ModalDialog from '../components/ModalDialog.vue'

export default {
  name: 'SnapBook',
  components: {
    ConfirmDialog,
    ModalDialog,
    // VueCropper,
    FileInput,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiImageMultipleOutline,
        mdiCrop,
        mdiRefresh,
      },
      componentKey: 0,
      componentKey1: 0,
      search: '',
      searchBook: null,
      isLoadingTable: true,
      isLoadingData: false,
      isDisabled: true,
      isLoadingButton: false,
      loadingImage: false,
      modalDialog: false,
      confirmDialog: false,
      formValid: false,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      width: 600,
      dialog: '',
      headers: [
        { text: 'Nama Buku', value: 'library.name' },
        { text: 'Tanggal', value: 'show_date' },
        { text: 'Actions', value: 'actions' },
      ],
      snapBooks: [],
      snapBook: {
        library: {
          uuid: null,
        },
        thumbnail: null,
        content: '',
        start_date: '',
        end_date: '',
      },
      start_date: '',
      end_date: '',
      minEndDate: '',
      libraries: [],
      filterQuery: {
        search: '',
      },
      selectedPacket: {},
      serviceSnapBook: 'snapbook',
      serviceLibrary: 'library',
      imgSrc: '',
      cropImg: '',
      componentValid: false,
      showPreview: false,
      imageCropped: false,
      showCropper: false,
      isLoadingLib: false,
    }
  },
  computed: {
    initialImage: {
      get() {
        if (typeof this.snapBook.thumbnail === 'string') {
          return new File([this.snapBook.thumbnail], this.snapBook.thumbnail, { type: 'text/plain' })
        }

        return this.snapBook.thumbnail
      },
      set() {
        return this.snapBook.thumbnail
      },
    },
  },
  watch: {
    page: {
      handler() {
        this.listSnapBook(this.filterQuery)
      },
    },
    snapBook: {
      handler() {
        const valid = []
        const requiredField = ['library_uuid', 'start_date', 'end_date', 'content']
        Object.entries(this.snapBook).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    searchBook: {
      handler(val) {
        if (this.searchBook.length > 3) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.page = 1
            this.searchBooks(this.searchBook)
          }, 500)
        }
      },
    },
  },
  created() {
    this.listSnapBook()
  },
  async mounted() {
    if (this.searchBook !== null) {
      await this.searchBooks({ search: this.searchBook })
    }
  },
  methods: {
    autofillStartDate() {
      this.snapBook.start_date = new Date().toISOString().substr(0, 10)
      this.isDisabled = false
    },

    autofillEndDate(val) {
      const selectedDate = new Date(val)
      this.snapBook.end_date = new Date(selectedDate.getTime() + (6 * 24 * 60 * 60 * 1000)).toISOString().substr(0, 10)
      this.minEndDate = this.snapBook.end_date
    },

    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.imageCropped = true
      this.showPreview = true
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        this.snapBook.thumbnail = blob
      }, this.mime_type)
    },
    reset() {
      this.$refs.cropper.reset()
      this.showPreview = false
      this.imageCropped = false
    },
    getFile(event) {
      // this.imageCropped = false
      this.snapBook.thumbnail = event
      // const file = event
      // if (file.type.indexOf('image/') === -1) {
      //   this.showSnackbar({
      //     text: 'Silahkan tambahkan satu file gambar',
      //     color: 'error',
      //   })

      //   return
      // }
      // if (typeof FileReader === 'function') {
      //   const reader = new FileReader()
      //   reader.onload = event => {
      //     this.imgSrc = event.target.result
      //     this.$refs.cropper.replace(event.target.result)
      //   }
      //   reader.readAsDataURL(file)
      // } else {
      //   this.showSnackbar({
      //     text: 'Maaf, FileReader API tidak didukung',
      //     color: 'error',
      //   })
      // }

      // this.showCropper = true
    },

    async listSnapBook(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.serviceSnapBook, { ...params, page: this.page }).then(
        ({ data }) => {
          this.snapBooks = data.data.map((snapBook, index) => ({
            ...snapBook,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },

    async searchBooks(val) {
      this.isLoadingLib = true
      await this.$services.ApiServices.list(this.serviceLibrary, {
        library: val,
      }).then(
        ({ data }) => {
          this.libraries = data.data
        },
        err => {
          this.isLoadingLib = false
          console.error(err)
        },
      )
      this.isLoadingLib = false
    },

    // async listLibrary(params = {}) {
    //   await this.$services.ApiServices.list(this.serviceLibrary, {
    //     ...params,
    //     per_page: 'all',
    //   }).then(
    //     ({ data }) => {
    //       this.libraries = data.data
    //     },
    //     err => {
    //       console.error(err)
    //     },
    //   )
    // },

    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('thumbnail', this.snapBook.thumbnail)
      this.formData.append('library_uuid', this.snapBook.library.uuid)
      this.formData.append('start_date', this.snapBook.start_date)
      this.formData.append('end_date', this.snapBook.end_date)
      this.formData.append('content', this.snapBook.content)

      await this.$services.ApiServices.add(this.serviceSnapBook, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.resetForm()
          this.listSnapBook(this.filterQuery)
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          this.modalDialog = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
      this.resetForm()
      this.listSnapBook(this.filterQuery)
      this.modalDialog = false
    },

    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('thumbnail', this.snapBook.thumbnail)
      this.formData.append('library_uuid', this.snapBook.library.uuid)
      this.formData.append('start_date', this.snapBook.start_date)
      this.formData.append('end_date', this.snapBook.end_date)
      this.formData.append('content', this.snapBook.content)
      if (typeof this.snapBook.thumbnail === 'string') {
        this.formData.delete('thumbnail')
      }

      await this.$services.ApiServices.update(this.serviceSnapBook, this.formData, this.snapBook.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.resetForm()
          this.listSnapBook(this.filterQuery)
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          this.modalDialog = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
      this.resetForm()
      this.listSnapBook(this.filterQuery)
      this.modalDialog = false
    },

    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.snapBook.uuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.serviceSnapBook, this.snapBook.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          this.listSnapBook(this.filterQuery)
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.confirmDialog = false
    },

    disabledComponent(formValid) {
      this.componentValid = formValid
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showFormAdd() {
      this.dialog = 'add'
      this.imageCropped = false
      this.showCropper = false
      this.showPreview = false
      this.loadingImage = false
      this.isDisabled = true
      this.resetForm()
      this.modalDialog = true
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.imageCropped = false
      this.showCropper = false
      this.showPreview = false
      this.loadingImage = true
      this.isDisabled = true
      this.resetForm()
      this.modalDialog = true
      this.isLoadingData = true
      await this.$services.ApiServices.get(this.serviceSnapBook, uuid).then(({ data }) => {
        this.snapBook = data.data
        this.isLoadingButton = false
      })
      this.isLoadingData = false
      this.loadingImage = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },

    handleCloseDialog() {
      this.modalDialog = false
      this.dialog = ''
      this.resetForm()
      this.listSnapBook(this.filterQuery)
    },

    searchHandler(data) {
      this.filterQuery.search = data
      this.page = 1
      this.listSnapBook(this.filterQuery)
    },

    resetForm() {
      this.snapBook = {
        library: {
          uuid: null,
        },
        thumbnail: null,
        content: '',
        start_date: '',
        end_date: '',
      }
    },
  },
}
</script>

<style>
.text-delete {
  color: #e30000 !important;
}

.cropper-container {
  height: 400px;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #ffffff;
  outline: auto !important;
}

.btn-reset, .btn-crop{
  width: 120px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 300px;
  background: #ccc;
}

.cropped-image img {
  width: 100%;
}
</style>
